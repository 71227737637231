import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfContent from "@/vue/domain/content/df-content";
import DfProperty from "@/vue/domain/content/df-property";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Carousel, Slide } from "vue-carousel-variable-width";
import DfStore from "@/vue/domain/store/df-store";
import DfBrand from "@/vue/domain/brand/df-brand";

@Component({
  components: {
    Carousel,
    Slide,
  },
})
export default class DfSectionBannerComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({
    type: Array,
    default: () => {
      [];
    },
  })
  banners!: Array<DfContent>;

  @Prop({ type: String, default: "" })
  position!: string;
  @Prop({ type: Boolean, default: false })
  carouselView!: boolean;
  @Prop({ type: Boolean, default: false })
  fullWidth!: boolean;
  @Prop({ type: Boolean, default: true })
  columnDirection!: boolean;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get carouselAutoplay(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_CAROUSEL_AUTOPLAY, "BOOLEAN");
  }

  get bannersFiletred(): Array<DfContent> {
    return this.banners.filter((banner: DfContent) => {
      const positionValue: any = Utils.getPropertyValue(banner, Utils.PROPERTY_POSITION, "LIST", {});
      const filteredByPosition: boolean = positionValue.key === "ALL" || positionValue.key === this.position;

      const targetValue: any = Utils.getPropertyValue(banner, Utils.PROPERTY_TARGET_DEVICE, "LIST", { key: "ALL" });
      const checkTargetDevice: boolean = Utils.isMobile() ? targetValue.key === "MOBILE" : targetValue.key === "DESKTOP";
      const filteredByTarget: boolean = targetValue.key === "ALL" || checkTargetDevice;

      return filteredByPosition && filteredByTarget;
    });
  }

  get bannersToShow(): Array<any> {
    const bannersToShow: Array<any> = [];
    this.bannersFiletred.forEach((banner: DfContent) => {
      const propertyIsFile: DfProperty = banner.properties ? banner.properties.find((property: DfProperty) => property.isFile) || null : null;
      const propertyLink: DfProperty = banner.properties ? banner.properties.find((property: DfProperty) => property.code === Utils.PROPERTY_LINK) || null : null;

      if (propertyIsFile && propertyIsFile.values.length > 0) {
        bannersToShow.push({
          previewUrl: `${Utils.getEndpointContextPath()}/files/${propertyIsFile.values[0].uniqueId}/${propertyIsFile.values[0].basename}.${propertyIsFile.values[0].extension}`,
          linkUrl: propertyLink && propertyLink.values.length > 0 ? propertyLink.values[0] : null,
        });
      }
    });

    return bannersToShow;
  }

  @Watch("bannersToShow", { immediate: true })
  private changeVisibility() {
    this.$emit("setVisibility", this.bannersToShow.length > 0);
  }

  private onClick(linkUrl: string) {
    if (linkUrl) {
      Utils.isExternalLink(linkUrl) ? window.open(linkUrl, "_blank") : this.$router.push({ path: linkUrl.startsWith("/") ? linkUrl : `/${linkUrl}` });
    }
  }
}
